<template>
  <v-card>
    <v-layout row wrap>
      <v-flex v-if="[2, 5].includes(props.item.type_id)" xs12 sm12 md12 lg12 class="justify-center pa-2">
        <v-btn small color="primary" @click="listHostsByDevice()">
          <v-icon>list</v-icon>
          <span class="ml-2">Listar Equipamentos/Clientes desse dispositivo</span>
        </v-btn>
      </v-flex>
      <v-flex xs12 sm12 md12 lg12>
        <v-list dense two-line dark>
          <v-list-tile avatar @click="$router.push({name: 'grupo-mapa-dispositivos', params: {id: props.item.grupo_dados_id}})">
            <v-list-tile-avatar>
              <v-icon large>show_chart</v-icon>
            </v-list-tile-avatar>
            <v-list-tile-content>
              <v-list-tile-sub-title>Redes associadas</v-list-tile-sub-title>
              <v-list-tile-title v-html="props.item.network_names"></v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
          <v-divider></v-divider>
        </v-list>
      </v-flex>
      <v-flex xs12 sm12 md6 lg6>
        <v-list dense two-line dark>
          <v-list-tile v-if="props.item.item_model" avatar>
            <v-list-tile-avatar>
              <v-icon large>surround_sound</v-icon>
            </v-list-tile-avatar>
            <v-list-tile-content>
              <v-list-tile-sub-title>Modelo e Fabricante</v-list-tile-sub-title>
              <v-list-tile-title v-html="props.item.item_model.name_manufacturer"></v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
          <v-divider></v-divider>
          <v-list-tile v-if="props.item.type" avatar>
            <v-list-tile-avatar>
              <v-icon large>settings_ethernet</v-icon>
            </v-list-tile-avatar>
            <v-list-tile-content>
              <v-list-tile-sub-title>Tipo de dispositivo</v-list-tile-sub-title>
              <v-list-tile-title v-html="props.item.type.name"></v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
        </v-list>
      </v-flex>
      <v-flex xs12 sm12 md6 lg6>
        <v-list dense two-line dark>
          <v-list-tile avatar >
            <v-list-tile-avatar>
              <v-icon large>vertical_split</v-icon>
            </v-list-tile-avatar>
            <v-list-tile-content>
              <v-list-tile-sub-title>MAC Address</v-list-tile-sub-title>
              <v-list-tile-title v-html="props.item.mac_disp"></v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
          <v-divider></v-divider>
          <v-list-tile avatar @click="$router.push({name: 'network-server', params: {id: props.item.network_server.id}})">
            <v-list-tile-avatar>
              <v-icon large>dns</v-icon>
            </v-list-tile-avatar>
            <v-list-tile-content>
              <v-list-tile-sub-title>Servidor de Monitoramento</v-list-tile-sub-title>
              <v-list-tile-title v-html="props.item.network_server.name"></v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
        </v-list>
      </v-flex>
    </v-layout>
  </v-card>
</template>
<script>
  export default {
    name: 'NetworkDevicesExpand',
    methods: {
      listHostsByDevice () {
        this.$router.push({ name: 'network-list', params: { page: 'host' }, query: { device_id: this.props.item.id } })
      }
    },
    props: {
      config: Object,
      props: Object
    }
  }
</script>